<template>
  <div class="contaner_e">
    <div class="earnings_clone" @click="cloneBtn">
          <img style="width:15px;height:15px" src="../../assets/leftBtn.png"/>
            <div>返回</div>
        </div>
    <div class="class_info">
      <div>余额:</div>
      <div class="price_e">{{headerData.user.union_shareholder_sales}}</div>
      <div class="getBtn" @click="getBtn">提取</div>
    </div>
    <div class="select_info">
      <div :class="select_e==1?'colorBtn':''" @click="lookBtn(1)">收入明细</div>
      <div :class="select_e==2?'colorBtn':''" @click="lookBtn(2)">提取记录</div>
    </div>
    <!-- 收益明細 -->
    <div class="earnings_info" v-if="select_e==1" @scroll="ononScroll">
      <div class="earnings_item" v-for="(item,index) in tableData" :key="index">
        <div>
          <div style="color:#000">{{item.create_time}}</div>
          <div>转让工分:{{item.log.shares}}</div>
          <div>单价:{{item.log.shares_price}}</div>
          <div>{{item.log.tax_rate*100}}%服务费</div>
        </div>
        <div class="price">{{item.amount}}</div>
      </div>
    </div>
    <!-- 提取记录 -->
    <div class="earnings_info" v-if="select_e==2">
      <div class="earnings_item" v-for="(item,index) in dataList" :key="index">
        <div>
          <div style="color:#000">申请时间</div>
          <div style="color:#000">{{item.create_time}}</div>
        </div>
        <div style="text-align:right">
          <div style="color:#000;margin-right:60px"><span>金额：</span>-{{item.cash_amount}}</div>
          <div style="color:#BBBBBB;margin-right:60px">{{item.total_amount}}</div>
          <div class="configBtn" v-if="item.cash_state==1">待处理</div>
          <div class="configBtn" style="background:#fff;color:#42B983" v-if="item.cash_state==2">提现成功</div>
          <div class="configBtn" style="background:#fff;color:#FF5E5E" v-if="item.cash_state==3">提现失败</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { withdrawal_api, submitWithdrawal_api } from "@/api/deal";
export default {
  data() {
    return {
      select_e: 1,
      tableData: [],
      headerData: {
        user: {}
      },
      dataList: [],
      queryList: {
        page: 1,
        limit: 10
      }
    };
  },
  created() {
    document.title = "收益中心";
        if(this.select_e==1){
          this.getList();
        }else{
          this.getpay()
        }
  },
  methods: {
    cloneBtn(){
      this.$router.go(-1)
    },
    ononScroll(e) {
      let scrollTop = e.target.scrollTop;

      let scrollHeight = e.target.scrollHeight;

      let offsetHeight = Math.ceil(e.target.getBoundingClientRect().height);

      let currentHeight = scrollTop + offsetHeight;

      if (currentHeight >= scrollHeight) {
        this.queryList.page++;
        if(this.select_e==1){
          this.getList();
        }else{
          this.getpay()
        }
        
      }
    },

    getBtn() {
      this.$router.push("./earning_form");
    },
    getList() {
      withdrawal_api(this.queryList).then(res => {
        if (res.code == 0) {
          this.tableData = this.tableData.concat(res.data);
          if (this.tableData.length == 0) {
            Toast({
              message: "暂无数据",
              icon: "success",
              duration: 1000
            });
          } else {
            if (res.data.length == 0) {
              Toast({
                message: "没有更多了",
                icon: "success",
                duration: 1000
              });
            }
          }

          this.headerData = res.pagination;
        }
      });
    },
    getpay() {
      submitWithdrawal_api(this.queryList).then(res => {
        if (res.code == 0) {
          // this.dataList = res.data;
           this.dataList = this.dataList.concat(res.data);
          if (this.dataList.length == 0) {
            Toast({
              message: "暂无数据",
              icon: "success",
              duration: 1000
            });
          } else {
            if (res.data.length == 0) {
              Toast({
                message: "没有更多了",
                icon: "success",
                duration: 1000
              });
            }
          }
        }
      });
    },
    // 选择收益
    lookBtn(index) {
      this.dataList = [];
      this.tableData = [];
      this.select_e = index;
      this.queryList.page=1;
       if(this.select_e==1){
          this.getList();
        }else{
          this.getpay()
        }
    }
  }
};
</script>
<style>
.contaner_e {
}
.earnings_clone {
  margin-left: 31px;
  color: #000000;
  font-size: 15px;
  font-weight: 800;
  margin-top: 30px;
}
.class_info .getBtn {
  width: 97px;
  height: 52px;
  background: #2e2e30;
  box-shadow: 0px 0px 15px rgba(46, 46, 48, 0.25);
  opacity: 1;
  border-radius: 5px;
  text-align: center;
  line-height: 52px;
  font-size: 25px;
  color: #fff;
}
.class_info {
  display: flex;
  font-size: 40px;
  margin: 33px 0 33px 31px;
}
.price_e {
  margin: 0 15px;
  color: #dbb476;
}
.select_info {
  display: flex;
}
.select_info div {
  width: 375px;
  height: 81px;
  text-align: center;
  line-height: 81px;
  background: #f7f7f9;
  box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
  opacity: 1;
  border-radius: 5px;
  font-size: 30px;
  color: #bbbbbb;
}

.select_info .colorBtn {
  background: #dbb476;
  color: #fff;
}

.earnings_info {
  padding: 40px 40px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 1000px;
}
.earnings_item {
  font-size: 25px;
  color: #bbbbbb;
  display: flex;
  justify-content: space-between;
}
.earnings_item div {
  margin-bottom: 10px;
}
.price {
  margin-top: 45px;
  margin-right: 116px;
  color: #000000;
}
.configBtn {
  font-size: 25px;
  color: #fff;
  width: 120px;
  height: 41px;
  background: #dbb476;
  opacity: 1;
  border-radius: 21px;
  text-align: center;
  line-height: 41px;
  margin-top: 31px;
  margin-left: 60px;
}
</style>
